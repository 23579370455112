<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员用券查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox searMg">
            <div title="学员信息" class="searchboxItem ci-full">
              <span class="itemLabel">学员信息:</span>
              <el-select v-model="retrievalData.userId" placeholder="请选择" no-data-text="没有数据" remote size="small"
                clearable :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span class="select-header_1">学员姓名</span>
                    <span class="select-header_1">身份证号码</span>
                    <span class="select-header_1">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="studentInfo.seaUserName" @input="superUserSelect" type="text" size="small"
                      placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="studentInfo.seaUserIdcard" @input="superUserSelect" type="text" size="small"
                      placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="studentInfo.seaUserMobile" @input="superUserSelect" type="text" size="small"
                      placeholder="学员电话" clearable style="margin-left: 50px; width: 140px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in studentInfo.userList" :key="index" :label="item.userName"
                  :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{
                item.userName
              }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="班级信息" class="searchboxItem ci-full">
              <span class="itemLabel">班级信息:</span>
              <el-select v-model="retrievalData.projectId" placeholder="请选择" remote size="small" clearable
                :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span class="select-header_2">班级编号</span>
                    <span class="select-header_2">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="classInfo.seaProjectCode" @input="superProjectSelect" type="text" size="small"
                      placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="classInfo.seaProjectName" @input="superProjectSelect" type="text" size="small"
                      placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in classInfo.projectList" :key="index" :label="item.projectName"
                  :value="item.projectId">
                  <span style="width: 150px; font-size: 13px">{{ item.projectCode }}</span>
                  <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="行政区划" class="searchboxItem">
              <span class="itemLabel" style="min-width: 6rem">行政区划:</span>
              <el-cascader clearable filterable v-model="retrievalData.areaId" :options="areaTreeList"
                :props="propsArea" size="small"></el-cascader>
            </div>
            <div title="结业状态" class="searchboxItem ci-full">
              <span class="itemLabel">结业状态:</span>
              <el-select size="small" v-model="retrievalData.graduationState" clearable placeholder="请选择结业状态">
                <el-option label="未结业" :value="false"></el-option>
                <el-option label="已结业" :value="true"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="机构名称" v-if="userJson.roleId == '1' || userJson.roleId == '-1'" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model="retrievalData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索" style="width: 100%">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="培训券名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">培训券名称:</span>
              <el-input v-model="retrievalData.yjn082" size="small" clearable placeholder="请输入培训券名称" />
            </div>
            <div title="认证状态" class="searchboxItem ci-full">
              <span class="itemLabel">认证状态:</span>
              <el-select size="small" v-model="retrievalData.authentication" clearable placeholder="请选择认证状态">
                <el-option v-for="item in authenticationList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-select size="small" v-model="retrievalData.shanxiUserCompanyState" clearable placeholder="请选择状态">
                <el-option v-for="item in shanxiUserCompanyStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="exportData">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" minWidth="80" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" minWidth="150" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" minWidth="100" />
              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaName" min-width="100" />
              <el-table-column label="年度" align="left" show-overflow-tooltip prop="year" min-width="100" />
              <el-table-column label="培训券码" align="left" show-overflow-tooltip prop="yjn081" min-width="100" />
              <el-table-column label="是否使用培训券" align="left" show-overflow-tooltip prop="useCouponsState"
                min-width="120">
                <template slot-scope="{row}">
                  {{ row.useCouponsState ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="企业名称" align="left" show-overflow-tooltip prop="compName" min-width="180" />
              <el-table-column label="班级编号" align="left" show-overflow-tooltip prop="projectCode" min-width="180" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="100" />
              <el-table-column label="入班时间" align="left" show-overflow-tooltip prop="joinProjectTime" min-width="150" />
              <el-table-column label="课程名称" align="left" show-overflow-tooltip prop="courseName" min-width="100" />
              <el-table-column label="总学时" align="left" show-overflow-tooltip prop="totalLessonNum" min-width="100" />
              <el-table-column label="已学学时" align="left" show-overflow-tooltip prop="studyLessonNum" min-width="100" />
              <el-table-column label="结业状态" align="left" show-overflow-tooltip prop="graduationState" min-width="100">
                <template slot-scope="{row}">
                  {{ row.graduationState ? '已结业' : '未结业' }}
                </template>
              </el-table-column>
              <el-table-column label="证书编号" align="left" show-overflow-tooltip prop="certNo" min-width="200" />
              <el-table-column label="完成率(%)" align="left" show-overflow-tooltip prop="percentage" min-width="100" />
              <el-table-column label="状态" align="left" show-overflow-tooltip prop="graduationState" min-width="100">
                <template slot-scope="{row}">
                  <template v-if="row.shanxiUserCompanyState == '10'">失败</template>
                  <template v-else-if="row.shanxiUserCompanyState == '20'">成功</template>
                  <template v-else>--</template>
                </template>
              </el-table-column>
              <el-table-column label="失败原因" align="left" show-overflow-tooltip prop="shanxiUserCompanyMsg" min-width="300" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "classListCom_classEndReminder",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        userId: "", // 学员id
        projectId: "", // 班级id
        areaId: "140000", // 行政区划
        graduationState: "", // 结业状态
        yjn082: "", // 培训券名称
        authentication: "", // 认证状态
        compId: "", // 机构名称
        shanxiUserCompanyState: "", // 状态
      },
      CompanyList: [],
      // 学员信息
      studentInfo: {
        seaUserName: "", // 姓名
        seaUserIdcard: "", // 身份证号
        seaUserMobile: "", // 手机号
        userList: [], // 数据
      },
      // 班级信息
      classInfo: {
        seaProjectName: "", // 班级名称
        seaProjectCode: "", // 班级di
        projectList: [], // 数据
      },
      authenticationList: [],
      areaTreeList: [], //行政区划
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 状态 - 下拉数据
      shanxiUserCompanyStateList: [
        {
          value: '10',
          label: '失败'
        },
        {
          value: '20',
          label: '成功'
        }
      ]
    };
  },
  created() {
    this.dataDictionary();
    this.getAreaTree()
    this.superUserSelect();
    this.superProjectSelect();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() { },
  methods: {
    // 机构搜索
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    //获取行政区划
    getAreaTree() {
      this.$post("/sys/area/tree?areaId=140000").then((ret) => {
        this.areaTreeList = ret.data;
      });
    },
    // 获取 - 数据字典
    dataDictionary() {
      const authenticationList = this.$setDictionary("AUTHENTICATION", "list");
      const list = [];
      for (const key in authenticationList) {
        list.push({
          value: key,
          label: authenticationList[key],
        });
      }
      this.authenticationList = list;
    },
    // 获取 - 学员信息
    superUserSelect(e) {
      let url
      if (this.userJson.roleId == '1' || this.userJson.roleId == '-1') {
        url = '/biz/user/superUserSelect'
      } else {
        url = '/biz/user/companyUserSelect'
      }
      this.$post(
        url,
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.studentInfo.seaUserName,
          idcard: this.studentInfo.seaUserIdcard,
          mobile: this.studentInfo.seaUserMobile,
          projectId: this.studentInfo.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.studentInfo.userList = [{}];
        } else {
          this.studentInfo.userList = res.data.list;
        }
      });
    },
    // 获取 - 学员信息 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      this.studentInfo = this.$options.data().studentInfo;
      this.superUserSelect();
    },
    // 获取 - 班级信息
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.classInfo.seaProjectName,
          projectCode: this.classInfo.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.classInfo.projectList = [{}];
        } else {
          this.classInfo.projectList = res.data.list;
        }
      });
    },
    // 获取 - 班级信息 - 下拉框出现/隐藏时触发
    clearProjectSearchModel(e) {
      if (e) {
        this.classInfo = this.$options.data().classInfo;
        this.superProjectSelect();
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        areaId: this.retrievalData.areaId,
        graduationState: this.retrievalData.graduationState,
        authentication: this.retrievalData.authentication,
        shanxiUserCompanyState: this.retrievalData.shanxiUserCompanyState,
      };
      if (this.retrievalData.userId) {
        params.userId = this.retrievalData.userId;
      }
      if (this.retrievalData.projectId) {
        params.projectId = this.retrievalData.projectId;
      }
      if (this.retrievalData.yjn082) {
        params.yjn082 = this.retrievalData.yjn082;
      }
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      this.doFetch({
        url: "/biz/sou/shanxi/userCompany/pageList",
        params,
        pageNum,
      });
    },
    // 导出 - 列表数据
    exportData() {
      const params = {
        areaId: this.retrievalData.areaId,
        graduationState: this.retrievalData.graduationState,
        authentication: this.retrievalData.authentication,
      };
      if (this.retrievalData.userId) {
        params.userId = this.retrievalData.userId;
      }
      if (this.retrievalData.projectId) {
        params.projectId = this.retrievalData.projectId;
      }
      if (this.retrievalData.yjn082) {
        params.yjn082 = this.retrievalData.yjn082;
      }
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      this.$post("/biz/sou/shanxi/userCompany/exportList", params)
        .then((res) => {
          if (res.status == "0") {
            let list = [...res.data];
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;

  .select-header_1 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    margin-left: 50px;
    width: 140px;

    &:first-child {
      width: 100px;
      margin-left: 0;
    }
  }

  .select-header_2 {
    color: #8492a6;
    float: left;
    font-size: 13px;

    &:first-child {
      width: 150px;
    }

    &:last-child {
      width: 250px;
      margin-left: 50px;
    }
  }
}
</style>